
.loginformadmin{
width: 40%;
height: auto;
border: 1px solid #000;
padding: 2%;
border-radius: 15px;
margin: auto;
position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);

}
.loginformadmin .form input{
    height: 40px;
}
.loginformadmin{
    text-align: center;
}
.query{
  padding: 5%;
}
.userDetails{
  text-align: left;
  padding: 3%;
}
