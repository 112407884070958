.card{
    width: 350px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    height: auto;
}
.img{
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 180px;
}
h5{
    text-align: center;
    margin-top: 20px;
    margin-bottom: 15px;
}
.card h6{
    display: inline-block;
    font-weight: 700;
   margin-left: 10px;
}
.card p{
    display: inline-block;
    margin-left: 15px;
    background-color: hsl(0, 0%, 80%);
    padding: 1% 2%;
    border-radius: 12px;
    font-size: .8rem;
}
.price p{
    position: absolute;
    bottom: 50px;
    right: 10px;
    font-size: 20px;
    padding: 3px 16px;
    border-radius: 15px;
    background-color: #000;
    color: #fff;
    align-items: center;
    opacity: .7;

}
@media only screen and (max-width: 1070px) {
    .card{
        width: 100%;
        
       
        border-radius: 5px;
        height: auto;
    }
  }

  @media only screen and (max-width: 540px){
    .card h6{
        display: inline-block;
        font-weight: 700;
       margin-left: 30px;
    }
    .card p{
        display: inline-block;
        margin-left: 2%;
        background-color: hsl(0, 0%, 80%);
        padding: 1% 2%;
        border-radius: 15px;
        
    }
    .price p{
        position: absolute;
        bottom: 50px;
        right: 10px;
        font-size: 20px;
        padding: 2px 10px;
        background-color: #000;
        color: #fff;
        align-items: center;
        opacity: .7;
    
    }

  }