.footer {
    margin-top: 50px;
   padding: 3%;
    background-color: #2c3e50;
    color: #fff;
    text-align: left!important;
}
.copyright {
    background-color: #1a252f;
}
.copyright a{
    color: #fff;
}
.footer ul{
    list-style: none;
    margin: 0;
    padding: 0;
    outline: none;
}
.footer ul li{
    margin-bottom: 5px;
    color: #F5F5F5;
    
}
.footer .social ul{
    display: inline-flex;
}
.footer .social ul li{

    margin-right: 10px;
}
.footer .social ul li a{
    color: #2c3e50;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 20px;
    transition: all 0.4s ease 0s;
    width: 40px;
    height: 40px;
    border-radius: 14px;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
}