.modalBackground {
   height: 100%;
   width: 100%;
   position: fixed;
   top: 0;
   left: 0;
   display: flex;
   justify-content: center;
   align-items: center;
  z-index: 1000;
  overflow-y: auto;
 
   
  
   
   
  }
  
  .modalContainer {
    position: absolute;
    top: 20px;
    width: 50%;
    height: auto;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
   z-index: 1000;
    text-align: center;
   animation: modal .3s;

   
   
   
   
  }
  @keyframes modal{
    from {
      width: 0;
      height: 0;
    }to {
      width: 50%;
      height: auto;
    }
  }
  
  .modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
    height: 40px;
    padding: 5px;
    
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
    color: #fff;
  }
  
  /* .modalContainer .body {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    text-align: center;
  } */
  
  .resend{
    margin-top: 20px;
  }
 .resend .btn{
   font-size: 15px;
   width: 115px;
   padding: 2px;
   margin-left: 5px;
 }
  .image{
    width: 100%;
  }
 .img-det{
   width: 100%;
   height: 300px;
 
 }
 .co_services{
   display: flex;
   flex-wrap: wrap;
   list-style-type: none;
   text-align: left;
 }
 .co_services li{
   flex: 25%;
 }
 .continue{
  margin-top: 15px;
  height: 40px;
  margin-left: auto;
  margin-right: 15px;
  margin-bottom: 15px;
  
  }
  input::placeholder{
    padding: 5px;
  }
  .form input{
    height: 30px;
  } 
  .form textarea{
    width: 100%;
  }
  
  @media screen and (max-width: 992px) {
    .modalContainer {
      width: 90%;
      height: auto;
      border-radius: 12px;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      display: flex;
      flex-direction: column;
      animation: modal .3s;
      text-align: center;
     
    }
    .co_services li{
      flex: 33%;
    }
    @keyframes modal{
      from {
        width: 0;
        height: 0;
      }to {
        width: 90%;
        height: auto;
      }
    }
    
  }
  @media screen and (max-width: 600px) {
    .co_services li {
      flex: 50%;
    }
  }
  .loginform{
    padding: 12%;
  }
  .otp{
    color: #fff;
    width: 100%;
    margin-top: 10px;
  }
.otp-i{
  width: 40%;
  border-top: none;
  border-right: none;
  border-left: none;
  margin-left: auto;
  margin-right: auto;
  outline: none;
  
}
.verify{
  padding: 10%;
}
.form input{
  width: 100%;
  margin-bottom: 5px;
  border-radius: 5px;
  outline: none;
 border: 1px solid black;
 margin-top: 5px;

}
.form{
  padding: 5%;
  text-align: left;
  
}

.choose-service select{
  margin-top: 5px;
  height: 30px;
  border-radius: 5px;
}
.submit{
  color: #fff;
  height: 40px!important;
}
.modalContainer h6{
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
}
.continue h6{
  display: inline-block;
  width: 100px;
}

@media only screen and (max-width: 600px){
  .choose-service input{
    width: 100%;
    margin-left: initial;
    padding: 5px;

    
    }
    .choose-service select{
      width: 100%;
      margin-left: initial;
      margin-bottom: 20px;
    }
}