body {
  margin: 0;
 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  overflow-x: hidden;
  font-family: "Poppins",sans-serif;
}

code {
 
}
